import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 用户信息
const getList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/companies', params)
}

// 用户首页 
const getGroup = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/companyGroup', params)
}


// 意见建议
const suggest = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/suggest/add', params)
}

// 意见建议列表
const suggestList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, '/api/suggest/list', {params})
}

// 意见建议回复
const suggestReply = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/suggest/reply', params)
}

// 意见建议总数
const suggestTotal = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/suggest/total', {params})
}

export default {
	getList,
	getGroup,
	suggest,
	suggestList,
	suggestReply,
	suggestTotal
}
